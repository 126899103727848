import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/http/api.service';
import { DropdownItem } from '../models/common.model';
import { API_URL } from './api.constant';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(
    private http: HttpClient,
    private httpClient: ApiService,
  ) {}

  getDropdown(
    params,
  ): Observable<{ [dropdownName: string]: DropdownItem[] }> {
    return this.httpClient.get<{
      [dropdownName: string]: DropdownItem[];
    }>(API_URL.dropdown, params);
  }

  getDropdownWithHeader(header, params) {
    return this.http.get(environment.baseUrl + API_URL.dropdown, {
      params,
      headers: header,
    });
  }

  memoTypeItem() {
    return [
      // {
      //   label: 'External',
      //   value: 'external',
      //   context: {
      //     display_name: 'แบบขออนุมัติภายนอก',
      //     display_name_en: 'External',
      //   },
      // },
      {
        label: 'Internal',
        value: 'internal',
        context: {
          display_name: 'แบบขออนุมัติภายใน',
          display_name_en: 'Internal',
        },
      },
      // {
      //   label: 'Purchase Request',
      //   value: 'purchase_request',
      //   context: {
      //     display_name: 'แบบขออนุมัติการซื้อ',
      //     display_name_en: 'Purchase Request',
      //   },
      // },
      {
        label: 'Announcement Form',
        value: 'announcement',
        context: {
          display_name: 'แบบขออนุมัติประกาศ',
          display_name_en: 'Announcement Form',
        },
      },
      {
        label: 'Order Form',
        value: 'order',
        context: {
          display_name: 'แบบขออนุมัติคำสั่ง',
          display_name_en: 'Order Form',
        },
      },
      {
        label: 'Upload',
        value: 'upload',
        context: {
          display_name: 'เอกสารอัปโหลด',
          display_name_en: 'Upload',
        },
      },
      {
        label: 'Contract',
        value: 'contract',
        context: {
          display_name: 'สัญญา',
          display_name_en: 'Contract',
        },
      },
    ];
  }
}

export interface DropdownResponse {
  [type: string]: {
    label: any;
    value: any;
    context?: any;
  }[];
}
