<button type="button" #openModal [hidden]="true" (click)="open(cropImg)"></button>
<div class="modal-content p-10">
  <ng-template #cropImg let-c="close" let-d="dismiss">
    <div class="modal-header align-items-center">
      <div class="col">
        <h4 class="mb-1">{{"CREATE-USER.ADD-SIGNATURE" | translate}}</h4>
      </div>
      <div class="col-auto">
        <i class="fas fa-times pointer size-24px" data-dismiss="modal" (click)="d('Cross click'); onClose()"></i>
      </div>
    </div>
    <div class="modal-body">
      <div [ngClass]="{'d-none': !signature || imageChangedEvent || imageURL}">
        <div class="row justify-content-center">
          <div class="col-4 align-self-center text-center bg-white">
            <img alt="image" class="img-fluid img-thumbnail blend-hard-light" [src]="signature">
            <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.CURRENT-SIGNATURE" | translate}}</h6>
          </div>
        </div>
      </div>
      <div class="row" [ngClass]="{'d-none': signature && !imageChangedEvent}">
        <div class="col-6 align-self-center text-center">
          <img alt="image" class="img-fluid img-thumbnail" *ngIf="!imageChangedEvent && !imageURL"
            src="assets/images/original.png">
          <image-cropper *ngIf="imageChangedEvent || imageURL" [imageChangedEvent]="imageChangedEvent"
            [imageURL]="imageURL" [maintainAspectRatio]="false" (imageCropped)="imageCropped($event)" imageFile
            (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()" format="png" outputType="both"
            class="img-thumbnail" #imageCropper>
          </image-cropper>
          <input type="file" class="d-none" (change)="fileChangeEvent($event)" #fileSignature>
          <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.ORIGINAL-IMAGE" | translate}}</h6>
        </div>
        <div class="col-6 align-self-center text-center">
          <img alt="image" class="img-fluid img-thumbnail" src="assets/images/preview.png"
            *ngIf="!croppedImage">
          <div class="img-thumbnail" *ngIf="croppedImage">
            <div class="overflow-hidden h-100pc">
              <div class="d-inline-block align-middle text-center h-100pc bg-white">
                <span class="d-inline-block align-middle h-100pc m-t-1"></span>
                <img alt="image" class="img-fluid align-middle mh-100pc outline-grey blend-hard-light"
                  [src]="croppedImage">
              </div>
            </div>
          </div>
          <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.SAMPLE-IMAGE" | translate}}</h6>
        </div>
      </div>
      <app-signature (saveImage)="onDraw($event)"></app-signature>
    </div>
    <div class="modal-footer" style="position: relative">
      <button type="button" class="btn btn-outline-secondary"
        (click)="fileSignature.click()">{{"CREATE-USER.UPLOAD" | translate}}</button>
      <button type="button" class="btn btn-dark" data-dismiss="modal"
        (click)="saveSignature()">{{"CREATE-USER.SAVE" | translate}}</button>
    </div>
  </ng-template>
</div>
